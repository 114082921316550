import React, { useEffect, useState } from 'react';
import styles from "./CreateGroup.module.css";
import { RiArrowDownSLine } from "react-icons/ri";
import Button from "../../../../Components/Button/button";
import axios from "../../../../axios/axios";
import AdminModal from "../../AdminCourses/CreateNewCourseAdmin/AdminModal/AdminModal";
import CheckBoxMulty from "../AdminCreateNewGroup/CheckBoxMulty/CheckBoxMulty";
import CustomD from "./CustomD/CustomD";
import AssignIDType from "../../AdminTest/TestsListInfo/AdminCreateATest/AssignIDType/AssignIDType";
import SelctorForCourses from "../../AdminCourses/CreateNewCourseAdmin/SelctorForCourses/SelctorForCourses";

function CreateGroup({ onUpdate }) {
    const [open, setOpen] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [modalOp, setModalOp] = useState(false);
    const [name, setName] = useState('');
    const [nameru, setNameru] = useState('');
    const [nameam, setNameam] = useState('');
    const [teacher, setTeacher] = useState([{ id: '', title: '' }]);
    const [openFormat1, setOpenFormat1] = useState(false);
    const [selectedItems1, setSelectedItems1] = useState({});
    const [teacherList, setTeacherList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [date, setDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [dateErr, setDateErr] = useState(null);
    const [dateErr1, setDateErr1] = useState(null);
    const [erros, setErros] = useState(true);
    const types = ['Full','Monthly','Bootcamp']
    const [selectedTypes, setSelectedTypes] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState([]);

    const [moreDetails, setMoreDetails] = useState([
        {
            titlePrice: '',
            desc_en: '',
            desc_ru: '',
            desc_am: '',
            price: '',
            discount: '',
        }
    ]);

    useEffect(() => {
        axios.get('/group/getTeachers')
            .then(response => {
                setTeacherList(response.data.users);
            })
            .catch(error => {
                console.error('Error fetching teachers: ', error);
            });

        const fetchData = async () => {
            try {
                const response = await axios.get(`/courses/getCourseTitlesForCreateGroup`);
                setCourses(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);
    const handleAddPrerequisite = () => {
        setMoreDetails(prevDetails => [
            ...prevDetails,
            {
                titlePrice: '',
                desc_en: '',
                desc_ru: '',
                desc_am: '',
                price: '',
                discount: '',
            }
        ]);
        setSelectedTypes(prevTypes => [...prevTypes, '']);
        setDropdownOpen(prev => [...prev, false]);
    };
    const handleMoreDetailsChange = (index, field, value) => {
        const updatedDetails = [...moreDetails];
        updatedDetails[index][field] = value;
        setMoreDetails(updatedDetails);
    };
    const toggleDropdown = (index) => {
        setDropdownOpen(prevState => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    };
    const handleTypeChange = (index, selectedType) => {
        const updatedSelectedTypes = [...selectedTypes];
        updatedSelectedTypes[index] = selectedType;
        setSelectedTypes(updatedSelectedTypes);

        handleMoreDetailsChange(index, 'titlePrice_en', selectedType);
    };
    const handleRemoveMoreDetail = (index) => {
        const updatedDetails = [...moreDetails];
        updatedDetails.splice(index, 1);
        setMoreDetails(updatedDetails);
    };
    const onChangeSkills = async () => {
        const paymentData = moreDetails.map(detail => ({
            title: detail.titlePrice_en,
            description_en: detail.desc_en,
            description_ru: detail.desc_ru,
            description_am: detail.desc_am,
            price: parseFloat(detail.price_en),
            discount: parseFloat(detail.discount_en)
        }));

        try {
            await axios.post('/group/create', {
                name_en: name,
                name_ru: nameru,
                name_am: nameam,
                assignCourseId: selectedItems1.id,
                startDate: date,
                endDate: endDate,
                users: teacher.map(t => t.id),
                payment: paymentData,
            });
            setModalOp(true);
            setErros(true);
        } catch (error) {
            console.error('Error creating group: ', error);
            setModalOp(true);
            setErros(false);
        }
    };
    const [selectedButton, setSelectedButton] = useState('ENG');
    const handleClick = (button) => {
        setSelectedButton(button);
    };
    return (
        <div>
            <p className={styles.tit}>Create a group</p>
            <div className={styles.btndiv}>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ENG' ? styles.selected : ''}`}
                    onClick={() => handleClick('ENG')}
                >
                    ENG
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'ARM' ? styles.selected : ''}`}
                    onClick={() => handleClick('ARM')}
                >
                    ARM
                </button>
                <button
                    className={`${styles.tagOption} ${selectedButton === 'RUS' ? styles.selected : ''}`}
                    onClick={() => handleClick('RUS')}
                >
                    RUS
                </button>

            </div>
            <div className={styles.cont}>
                {selectedButton === 'ENG' &&
                    <div className={styles.inpDiv}>
                    <p className={styles.name}>Group name</p>
                    <div className={styles.controler}>
                        <input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            placeholder={'Group name'}
                            className={styles.input}
                        />
                    </div>
                </div>
                }
                {selectedButton === 'RUS' &&
                    <div className={styles.inpDiv}>
                        <p className={styles.name}>Group name</p>
                        <div className={styles.controler}>
                            <input
                                value={nameru}
                                onChange={e => setNameru(e.target.value)}
                                placeholder={'Group name'}
                                className={styles.input}
                            />
                        </div>
                    </div>
                }
                {selectedButton === 'ARM' &&
                    <div className={styles.inpDiv}>
                        <p className={styles.name}>Group name</p>
                        <div className={styles.controler}>
                            <input
                                value={nameam}
                                onChange={e => setNameam(e.target.value)}
                                placeholder={'Group name'}
                                className={styles.input}
                            />
                        </div>
                    </div>
                }
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Teachers</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Choose teachers'}
                                    type="text"
                                    value={teacher.map(t => t.title).join(', ')}
                                    readOnly={true}
                                    onClick={() => setOpen5(!open5)}
                                />
                            </div>
                            <RiArrowDownSLine
                                className={!open5 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {open5 &&
                            <CheckBoxMulty
                                arr={teacherList}
                                value={(e) => setTeacher(e)}
                                setOpen={setOpen5}
                                displayId={true}
                                selectedItems={teacher}
                            />
                        }
                    </label>
                </div>
                <div className={styles.container}>
                    <div className={styles.divData}>
                        <p className={styles.name}>Start date</p>
                        <div className={styles.controler1}>
                            <CustomD
                                average
                                putDate={(newDate) => setDate(newDate)}
                                selectedDate={date}
                                error={dateErr}
                            />
                            {dateErr && <p className={styles.error}>Birth Date is not valid</p>}
                        </div>
                    </div>
                    <div className={styles.divData}>
                        <p className={styles.name}>End date</p>
                        <div className={styles.controler1}>
                            <CustomD
                                average
                                putDate={(newDate) => setEndDate(newDate)}
                                error={dateErr1}
                                selectedDate={endDate}
                            />
                            {dateErr1 && <p className={styles.error}>Birth Date is not valid</p>}
                        </div>
                    </div>
                </div>
                <div className={styles.inpDiv}>
                    <p className={styles.name}>Assign course</p>
                    <label className={styles.label}>
                        <div className={styles.controler}>
                            <div>
                                <input
                                    className={styles.input}
                                    style={{cursor: 'pointer'}}
                                    placeholder={'Add course for this group'}
                                    type="text"
                                    value={selectedItems1.title}
                                    readOnly={true}
                                    onClick={() => setOpenFormat1(!open)}
                                />
                            </div>
                            <RiArrowDownSLine
                                className={!open5 ? styles.arrow : styles.arrow + ' ' + styles.arrowhov}
                            />
                        </div>
                        {openFormat1 &&
                            <AssignIDType
                                arr={courses && courses}
                                value={(e) => setSelectedItems1(e)}
                                setOpen={setOpenFormat1}
                                displayId={true}
                            />
                        }
                    </label>
                </div>
                <p className={styles.paymentTitle}>Payment</p>
                { moreDetails.map((detail, index) => (
                    <div style={{width: '100%'}} key={index}>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Type</p>
                            <label className={styles.label}>
                                <div className={styles.controler}>
                                    <div>
                                        <input
                                            className={styles.input}
                                            style={{cursor: 'pointer'}}
                                            placeholder={'Type'}
                                            type="text"
                                            value={detail.titlePrice_en}
                                            readOnly={true}
                                            onClick={() => toggleDropdown(index)}
                                        />
                                    </div>
                                    <RiArrowDownSLine
                                        className={!dropdownOpen[index] ? styles.arrow : `${styles.arrow} ${styles.arrowhov}`}/>
                                </div>
                                {dropdownOpen[index] && (
                                    <SelctorForCourses
                                        arr={types.filter(type => !selectedTypes.includes(type) || type === detail.titlePrice_en)}
                                        value={(selectedLevel) => handleTypeChange(index, selectedLevel)}
                                        setOpen={() => toggleDropdown(index)}
                                    />
                                )}
                            </label>
                        </div>
                        {selectedButton === 'ENG' && <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.desc_en}
                                    onChange={e => handleMoreDetailsChange(index, 'desc_en', e.target.value)}
                                    placeholder={'Description'}
                                />
                            </div>
                        </div>}
                        {selectedButton === 'RUS' && <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.desc_ru}
                                    onChange={e => handleMoreDetailsChange(index, 'desc_ru', e.target.value)}
                                    placeholder={'Description'}
                                />
                            </div>
                        </div>}
                        {selectedButton === 'ARM' && <div className={styles.inpDiv}>
                            <p className={styles.name}>Description</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.desc_am}
                                    onChange={e => handleMoreDetailsChange(index, 'desc_am', e.target.value)}
                                    placeholder={'Description'}
                                />
                            </div>
                        </div>}
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Price</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.price_en}
                                    onChange={e => handleMoreDetailsChange(index, 'price_en', e.target.value)}
                                    placeholder={'Price'}
                                />
                            </div>
                        </div>
                        <div className={styles.inpDiv}>
                            <p className={styles.name}>Discount</p>
                            <div style={{marginTop: '20px'}} className={styles.controler}>
                                <input
                                    className={styles.input}
                                    type={'text'}
                                    value={detail.discount_en}
                                    onChange={e => handleMoreDetailsChange(index, 'discount_en', e.target.value)}
                                    placeholder={'Discount'}
                                />
                            </div>
                        </div>
                        <div className={styles.delete}>
                            {moreDetails.length > 1 && (
                                <button className={styles.deleteButton} onClick={() => handleRemoveMoreDetail(index)}>
                                    Delete this one
                                </button>
                            )}
                        </div>
                    </div>
                ))}
                <div className={styles.inpDiv}>
                    <button className={styles.big} onClick={handleAddPrerequisite}>Add new option</button>
                </div>
                <div className={styles.btns}>
                    <Button
                        handleButtonClick={onChangeSkills}
                        disabled={selectedItems1.id === '' || name.length === 0}
                        className={'bigPrimary'}
                        text={'Create'}
                        styles={{padding: '6px 60px'}}
                    />
                </div>
            </div>
            {modalOp && (
                <AdminModal
                    link={'/admin/myGroup'}
                    setOpen={setModalOp}
                    question={erros ? `${name} group has been successfully created.` : 'Something went wrong'}
                    boxShadow
                />
            )}
        </div>
    );
}

export default CreateGroup;
