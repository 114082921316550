import React, { useEffect, useState, useRef } from 'react';
import styles from './chatsidebar.module.css';
import { useNavigate } from "react-router-dom";
import { ROLES, useUserContext } from "../../../Components/UserContext/UserContext";
import axios from "../../../axios/axios";
import { useTranslation } from "react-i18next";

function ChatSidebar({ groupData, groupChatId, chatId, oneToOneData, connectedUser, newMessages, setNewMessages }) {
    const { user } = useUserContext();
    const navigate = useNavigate();
    const [members, setMembers] = useState({ students: [], teachers: [] });
    const { t } = useTranslation();
    const membersContainerRef = useRef(null);

    useEffect(() => {
        const isActiveChat = newMessages?.chatNotification?.some(
            message => message?.receiver?.id === chatId
        );

        if (membersContainerRef.current && !isActiveChat) {
            membersContainerRef.current.scrollTop = 0;
        }
    }, [newMessages, chatId]);

    const getUnreadMessages = (id, type) => {
        const notifications = newMessages || {};
        const key = type === 'group' ? 'groupChatNotification' : 'chatNotification';
        return notifications[key]?.find(message => (type === 'group' ? message?.id : message?.receiver?.id) === id)?.notification || 0;
    };

    const getLastMessage = (id, type) => {
        const notifications = newMessages || {};
        const key = type === 'group' ? 'groupChatNotification' : 'chatNotification';
        return notifications[key]?.find(message => (type === 'group' ? message?.id : message?.receiver?.id) === id)?.lastMessage?.text || '';
    };

    const sortByUnreadMessages = (a, b, type) => {
        const unreadA = getUnreadMessages(a.id, type);
        const unreadB = getUnreadMessages(b.id, type);

        if (unreadA > unreadB) return -1;
        if (unreadA < unreadB) return 1;
        return 0;
    };

    useEffect(() => {
        const isAdmin = user.role === ROLES.ADMIN;
        const groupMembers = isAdmin ? groupData?.members : groupData?.members;

        const updatedMembers = groupMembers?.map(member => {
            if (member.id === ((oneToOneData?.firstId === user.id) ? oneToOneData?.secondId : oneToOneData?.firstId)) {
                return { ...member, chatId: oneToOneData?.id };
            } else {
                return member;
            }
        });

        const membersWithStatus = updatedMembers?.map(member => ({
            ...member,
            online: connectedUser?.id === member.id ? connectedUser.online : member.online,
        }));

        const teachers = membersWithStatus
            ?.filter(member => (member.role === ROLES.TEACHER || member.role === ROLES.ADMIN) && member.id !== user.id)
            ?.sort((a, b) => sortByUnreadMessages(a, b, 'individual'));

        const students = membersWithStatus
            ?.filter(member => member.role === ROLES.STUDENT && member.id !== user.id)
            ?.sort((a, b) => sortByUnreadMessages(a, b, 'individual'));

        setMembers({ students, teachers });
    }, [groupData, oneToOneData, connectedUser, newMessages, user.role]);

    const handleGroupChatClick = chatId => {
        navigate(`/${user.role.toLowerCase()}/messages?groupChatId=${chatId}`);
    };

    useEffect(() => {
        if (groupChatId && newMessages?.groupChatNotification) {
            const updatedNotifications = { ...newMessages };

            const groupNotification = updatedNotifications.groupChatNotification.find(
                message => message?.id === +groupChatId
            );

            if (groupNotification && groupNotification.notification > 0) {
                groupNotification.notification = 0;
                setNewMessages(updatedNotifications);
            }
        }
    }, [newMessages, groupChatId]);

    const handleMemberClick = memberId => {
        axios.post('/chat/createChat', {
            receiverId: memberId
        }).then(({ data }) => {
            navigate(`/${user.role.toLowerCase()}/messages?groupChatId=${groupChatId}&chatId=${data.id}`);
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.memberCont}>
                <p className={styles.title}>{t('chat.group')}</p>
            </div>
            <div className={`${styles.cont} ${styles.contMaxSmall}`}>
                {user?.groupChats
                    ?.sort((a, b) => sortByUnreadMessages(a, b, 'group'))
                    ?.map((group, index) => (
                        <div key={index} className={styles.smallCont} style={index === 0 ? { borderTop: 'none' } : {}}>
                            <div
                                className={`${styles.member} ${(group?.id === +groupChatId && !chatId) ? styles.selected : ''}`}
                                onClick={() => handleGroupChatClick(group.id)}
                            >
                                <div
                                    style={{ background: `url(${`https://platform.tesvan.com/server/${group?.image}`})` }}
                                    className={styles.user}
                                />
                                <div className={styles.nameCont}>
                                    <p className={styles.name}>{group?.name}</p>
                                    {group?.id !== +groupChatId &&
                                        <p className={`${styles.lastMessage} ${styles.ellipsis}`}>
                                            {getLastMessage(group.id, 'group')}
                                        </p>
                                    }
                                </div>
                                {getUnreadMessages(group.id, 'group') && group?.id !== +groupChatId ? (
                                    <div className={styles.notReadMessages}>{getUnreadMessages(group.id, 'group')}</div>
                                ) : (
                                    (group?.id !== +groupChatId || chatId) && (
                                        <img src={'/images/messageenv.png'} className={styles.envelope} alt={'message'} />
                                    )
                                )}
                            </div>
                        </div>
                    ))}
            </div>
            {members?.students?.length ? (
                <>
                    <p className={`${styles.title} ${styles.paddingTop}`}>{t('chat.students')}</p>
                    <div className={`${styles.cont} ${styles.contMaxSmall}`} ref={membersContainerRef}>
                        {members?.students?.map((student, index) => (
                            <div key={index} className={styles.smallCont} style={index === 0 ? { borderTop: 'none' } : {}}>
                                <div
                                    className={`${styles.member} ${student?.chatId === +chatId ? styles.selected : ''}`}
                                    onClick={() => handleMemberClick(student.id)}
                                >
                                    <div
                                        style={{ background: `url(${`https://platform.tesvan.com/server/${student?.image}`})` }}
                                        className={styles.user}
                                    >
                                        {student?.online && <div className={styles.circle} />}
                                    </div>
                                    <div className={styles.nameCont}>
                                        <p className={styles.name}>{student?.firstName} {student?.lastName}</p>
                                        <p className={`${styles.lastMessage} ${styles.ellipsis}`}>
                                            {getLastMessage(student?.id, 'individual')}
                                        </p>
                                    </div>
                                    {getUnreadMessages(student?.id, 'individual') ? (
                                        <div className={styles.notReadMessages}>
                                            {getUnreadMessages(student?.id, 'individual')}
                                        </div>
                                    ) : (
                                        student?.chatId !== +chatId && (
                                            <img src={'/images/messageenv.png'} className={styles.envelope} alt={'message'} />
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : undefined}
            {members?.teachers?.length ? (
                <>
                    <p className={`${styles.title} ${styles.paddingTop}`}>{t('chat.teachers')}</p>
                    <div className={`${styles.cont} ${styles.contMaxSmall}`}>
                        {members?.teachers?.map((teacher, index) => (
                            <div key={index} className={styles.smallCont} style={index === 0 ? { borderTop: 'none' } : {}}>
                                <div
                                    className={`${styles.member} ${teacher?.chatId === +chatId ? styles.selected : ''}`}
                                    onClick={() => handleMemberClick(teacher.id)}
                                >
                                    <div
                                        style={{ background: `url(${`https://platform.tesvan.com/server/${teacher?.image}`})` }}
                                        className={styles.user}
                                    >
                                        {teacher?.online && <div className={styles.circle} />}
                                    </div>
                                    <div className={styles.nameCont}>
                                        <p className={styles.name}>{teacher?.firstName} {teacher?.lastName}</p>
                                        <p className={`${styles.lastMessage} ${styles.ellipsis}`}>
                                            {getLastMessage(teacher?.id, 'individual')}
                                        </p>
                                    </div>
                                    {getUnreadMessages(teacher?.id, 'individual') ? (
                                        <div className={styles.notReadMessages}>
                                            {getUnreadMessages(teacher.id, 'individual')}
                                        </div>
                                    ) : (
                                        teacher?.chatId !== +chatId && (
                                            <img src={'/images/messageenv.png'} className={styles.envelope} alt={'message'} />
                                        )
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : undefined}
        </div>
    );
}

export default ChatSidebar;