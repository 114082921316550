import React, {useEffect, useState} from 'react';
import styles from "./AdminCourseLessons.module.css"
import {Link, useParams} from "react-router-dom";
import Switch from '@mui/material/Switch';
import axios from "../../../../../axios/axios";
import Loader from "../../../../../Components/Loader/Loader";
import {useUserContext} from "../../../../../Components/UserContext/UserContext";

function AdminCourseLessons(props) {
    const [checked, setChecked] = useState(null);
    const { courseIdEdit } = useParams();
    const [loading, setLoading] = useState(true);
    const {user} = useUserContext()

    const[course,setCourse] = useState([])

    const handleChange = async (id) => {
        try {
            setChecked((prev) => ({ ...prev, [id]: !prev[id] }));
            // setUp((prev) => ({ ...prev, [id]: !prev[id] }));

            await axios.patch('/lessons/openLesson', {
                id: id,
                courseId: courseIdEdit,
            });

        } catch (error) {
            console.error('Error updating email:', error);
        }
    };
    useEffect(() => {

        if(course.length) {
            const transformedData = course.reduce((acc, curr) => {
                acc[curr.id] = curr.isOpen;
                return acc;
            }, {});
            setChecked(transformedData)
        }
    }, [course])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/courses/getCourseForAdmin/${courseIdEdit}`);
                setCourse(response.data.lessons);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.contless}>
                    <>
                        {
                            loading ? (
                                <Loader />
                            ): (
                                checked !== null && course?.map((i, index) => (
                                    <Link
                                        id="class"
                                        className={checked[i.id] ? styles.courscont : styles.courscontdis}
                                        to={`/${user?.role.toLowerCase()}/lessons/editlesson/${i.id}`}
                                        key={index}
                                    >
                                        <div className={styles.top}>
                                            <div className={styles.topdiv}>
                                                <p className={styles.num}>{i.number}</p>
                                                <p className={styles.title}>{i.title}</p>
                                            </div>
                                            {/*<div onClick={(e) => e.stopPropagation()} className={styles.switch}>*/}
                                            {/*    <Switch*/}
                                            {/*        checked={checked[i.id]}*/}
                                            {/*        onChange={() => handleChange(i.id)}*/}
                                            {/*        color="default"*/}
                                            {/*        icon={<img className={styles.imgcl} src={'/images/loock.png'} />}*/}
                                            {/*        checkedIcon={<img className={styles.imgop} src={'/images/unlock.png'} />}*/}
                                            {/*    />*/}
                                            {/*</div>*/}
                                        </div>
                                        <p className={styles.text}>{i.description}</p>
                                    </Link>
                                ))
                            )
                        }
                    </>
            </div>
        </div>
    );
}

export default AdminCourseLessons;