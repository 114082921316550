import React, { useState, useRef, useEffect } from "react";
import styles from './certificationteacher.module.css';
import moment from "moment";
import { ROLES, useUserContext } from "../../Components/UserContext/UserContext";
import axios from "../../axios/axios";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import Button from "../../Components/Button/button";
import { useParams } from "react-router-dom";

function CertificationTeacher() {
    const { user } = useUserContext();
    const [students, setStudents] = useState([]);
    const [count, setCount] = useState(10);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [loadingDownloadId, setLoadingDownloadId] = useState(null);
    const dropdownRef = useRef(null);
    const typeRef = useRef(null);
    const { groupId } = useParams();

    useEffect(() => {
        axios.get(`/certifictaes/getAllStudents?groupId=${groupId}`)
            .then(({ data }) => setStudents(data));
    }, [groupId]);

    useEffect(() => {
        const handleClick = e => {
            if (!dropdownRef?.current?.contains(e.target) && !typeRef?.current?.contains(e.target)) {
                setOpenDropdownIndex(null);
            }
        };
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);

    const handleTypeClick = (clickedIndex) => {
        if (user.role === ROLES.ADMIN) {
            setOpenDropdownIndex(clickedIndex !== openDropdownIndex ? clickedIndex : null);
        }
    };

    const handleDownloadPDF = (id, name) => {
        setLoadingDownloadId(id);

        axios.post(`/certifictaes/${id}`, null, {
            responseType: 'blob'
        }).then(({ data }) => {
            const blob = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', `${name}_Certificate.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            setLoadingDownloadId(null);
        });
    };

    return (
        <>
            <div className={styles.titleCont}>
                <div>
                    <p className={styles.certification}>Certification</p>
                </div>
            </div>
            <div className={styles.container}>
                <div className={styles.numberCont}>
                    <p className={styles.performances}>Student performances</p>
                    <p className={styles.number}>{students.length} students</p>
                </div>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <td>Students</td>
                        <td>Certificate</td>
                        <td>Points</td>
                        <td>Date of award</td>
                    </tr>
                    </thead>
                    <tbody>
                    {students.slice(0, count).map((student, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    <div className={styles.cell}>
                                        <img className={styles.student} src={`https://platform.tesvan.com/server/${student.image}`} alt={'student'} />
                                        <p className={styles.name}>{student.name}</p>
                                    </div>
                                </td>
                                <td className={student.type ? styles[student.type || ''] : null}>
                                    <div className={styles.type} ref={typeRef} onClick={() => user.role === ROLES.ADMIN && handleTypeClick(index)}>
                                        {!student.giveDate ? (
                                            <hr className={styles.line} />
                                        ) : (
                                            <Button
                                                handleButtonClick={() => handleDownloadPDF(student?.id, student?.name)}
                                                className={'bigPrimary'}
                                                text={
                                                    loadingDownloadId === student.id ? (
                                                        <FaSpinner className={styles.spinner} />
                                                    ) : (
                                                        <p>Download</p>
                                                    )
                                                }
                                                styles={{ alignItems: 'center',  width: '150px', padding: '10px 20px'}}
                                                disabled={loadingDownloadId === student.id}
                                            />
                                        )}
                                    </div>
                                </td>
                                <td className={student.points ? `${styles.date} ${styles[student.type || '']}` : null}>
                                    {!student.points ? <hr className={styles.line} /> : `${student.points}`}
                                </td>
                                <td className={student.giveDate ? styles.date : null}>
                                    {!student.giveDate ? <hr className={styles.line} /> : `${moment(student.giveDate).format("DD.MM.YYYY")}`}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {students.length > 10 &&
                    <button
                        className={styles.button}
                        onClick={() => {
                            setCount(count === 10 ? students.length : 10);
                        }}
                    >
                        {count === students.length ? "See less" : "See more"}
                    </button>
                }
            </div>
        </>
    );
}

export default CertificationTeacher;