import React from 'react';
import styles from "./LessonsRightBoxes.module.css"
import {Link} from "react-router-dom";
import {ROLES, useUserContext} from "../../Components/UserContext/UserContext";
import {styled} from "@mui/material/styles";
import LinearProgress, {linearProgressClasses} from "@mui/material/LinearProgress";
import {useTranslation} from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 15,
    backgroundColor: 'rgba(255, 192, 56, 0.2)',
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        background: 'linear-gradient(to right, rgba(255, 192, 56, 1), rgba(226, 205, 160, 1))',
    },
}));

function LessonsRightBoxes({progres}) {
    const {t} = useTranslation();
    const isEmpty = (arr) => !arr || arr.length === 0;

    const buildItems = (key) => {
        if (isEmpty(progres?.[key])) {
            return [];
        }
        return progres[key].map(item => ({
            type: key,
            icon: key === "quizz" ? "/images/lessonsQuizIcon.png" : (key === "homework" ? "/images/homeWorkRight.png" : "/images/presentation.png"),
            title: item.title,
            text: item.description,
            id: item.id,
            url: item.url,
            point: item?.point
        }));
    };

    const quizItems = buildItems("quizz");
    const homeworkItems = buildItems("homework");
    const presentationItems = buildItems("Presentations");

    const {user} = useUserContext();

    return (
        <>
            {user.role === ROLES.STUDENT && (
                <div className={styles.cont}>
                    { presentationItems.concat(quizItems, homeworkItems).map((i, index) => (
                        <div key={index} className={styles.container}>
                            {i.type === "Presentations" ? (
                                    <a href={`https://platform.tesvan.com/server/${i.url}`} target='_blank' className={styles.links}>
                                        <div className={styles.up}>
                                            <img className={styles.imgs} src={i.icon} alt={'/'}/>
                                            <p className={styles.title}>{t('presentationText')}</p>
                                        </div>
                                        {i.text1 && <p className={styles.text1}>{i.text1}</p>}
                                        {i.type === 'quizz' && progres?.quizzPoint !== null && (
                                            <div className={styles.linerProg}>
                                                <p className={styles.pointcol}>{progres?.quizzPoint}</p>
                                                <div style={{width: '100%'}}>
                                                    <BorderLinearProgress variant="determinate" value={progres?.quizzPoint / progres?.maxQuizzPoints * 100}/>
                                                </div>
                                                <p className={styles.pointcol}>{progres?.maxQuizzPoints}</p>
                                            </div>
                                        )}
                                    </a>
                                ) : (
                                    <Link to={i.type === "quizz" ? `quiz/${i.id}` : `homework/${i.id}`} className={styles.links}>
                                        <div className={styles.up}>
                                            <img className={styles.imgs} src={i.icon} alt={'/'}/>
                                            <p className={styles.title}>{i.type === "quizz" ? t('userQuiz.startQuiz') : i?.title}</p>
                                        </div>
                                        <p className={styles.text}>{i.type === "quizz" ? t('userQuiz.stayFocused') : <div>{i?.text.replace(/<\/?[^>]+(>|$)/g, "")}</div>}</p>
                                        {i.text1 && <p className={styles.text1}>{i.text1}</p>}
                                        {((i.type === 'quizz' && progres?.quizzPoint !== null) || (i.type !== 'quizz' && progres?.homeworkPoint?.find(homework => homework?.homeworkId === i?.id)?.points)) ? (
                                            <div className={styles.linerProg}>
                                                <p className={styles.pointcol}>{i.type === 'quizz' ? progres?.quizzPoint : progres?.homeworkPoint?.find(homework => homework?.homeworkId === i?.id)?.points}</p>
                                                <div style={{width: '170%'}}>
                                                    <BorderLinearProgress variant="determinate" value={i.type === 'quizz' ? progres?.quizzPoint / progres?.maxQuizzPoints * 100 : progres?.homeworkPoint?.find(homework => homework?.homeworkId === i?.id)?.points / +i?.point * 100}/>
                                                </div>
                                                <p className={styles.pointcol}>{i.type === 'quizz' ? progres?.maxQuizzPoints : i?.point} </p>
                                            </div>
                                        ) : undefined}
                                    </Link>
                                )
                            }
                        </div>
                    ))}
                    {progres?.video?.map(video => {
                            return (
                                <div key={video?.id} className={styles.container}>
                                    <a href={`https://platform.tesvan.com/server/${video?.url}`} className={styles.links}>
                                        <div className={styles.up}>
                                            {/*<img className={styles.imgs} src={i.icon} alt={'/'}/>*/}
                                            <p className={styles.title}>Click to see the video!</p>
                                        </div>
                                        {/*<p className={styles.text}>{i.type === "quizz" ? t('userQuiz.stayFocused') : t('homework.homeworkDescription')}</p>*/}
                                        {/*{i.text1 && <p className={styles.text1}>{i.text1}</p>}*/}
                                    </a>
                                </div>
                            )
                        })
                    }
                </div>
            )}
            {user.role === ROLES.TEACHER && (
                <div className={styles.cont}>
                    {quizItems.concat(homeworkItems, presentationItems).map((i, index) => (
                        <div key={index} className={styles.container}>
                            {i.type === "Presentations" ? (
                                <a href={`https://platform.tesvan.com/server/${i.url}`} target='_blank' className={styles.links}>
                                    <div className={styles.up}>
                                        <img src={i.icon} alt={'/'}/>
                                        <p className={styles.title}>{i.title}</p>
                                    </div>
                                    <p className={styles.text}>{i.text}</p>
                                    {i.text1 && <p className={styles.text1}>{i.text1}</p>}
                                </a>
                            ) : (
                                <Link to={String(i.id)} className={styles.links}>
                                    <div className={styles.up}>
                                        <img src={i.icon} alt={'/'}/>
                                        <p className={styles.title}>{i.title}</p>
                                    </div>
                                    <p className={styles.text}>{i.text}</p>
                                    {i.text1 && <p className={styles.text1}>{i.text1}</p>}
                                </Link>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default LessonsRightBoxes;
