import React, {useEffect, useState} from "react";
import styles from './TableAdminCourses.module.css'
import {Link, useLocation, useSearchParams} from "react-router-dom";
import axios from "../../../../axios/axios";
import Loader from "../../../../Components/Loader/Loader";
import Modal from "../../../../Components/Modal/Modal";
import courseID from "../CourseID/CourseID";
import user from "../../../../Dashboard/Home/User/User";
import {ROLES, useUserContext} from "../../../../Components/UserContext/UserContext";
function TableAdminCourses() {

    const [searchParams] = useSearchParams()
    const [count, setCount] = useState(10)
    const [assign, setAssign] = useState([])
    const [update, setUpdate] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const {user} = useUserContext()

    const isNew = searchParams.get('isNew')

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`/courses/${user?.role === ROLES.TEACHER ? 'getCourseTitleForTeacher' :  'getCourseTitles'}`);
                setAssign(response.data);
            } catch (error) {
                console.error("Error fetching user courses:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [update, isNew]);

    const handleDelete = (courseId) => {
        axios.delete(`/courses/delete/${courseId}`).then(() => {
            setDeleteModalOpen(false);
            setUpdate(!update);
        });
    };

    return(
        <>{ loading ? (
            <Loader />
        ) :
            (<div className={styles.container}>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <td>Course name</td>
                        <td>Description</td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    {assign?.slice(0, count).map((course, index) => {
                        return (
                            <tr key={index}>
                                <td>
                                    {user?.role === ROLES.TEACHER ?
                                        <Link to={`${course.id}/lessons`} className={styles.cell}>
                                            {
                                                <p className={styles.name}>
                                                {course.title} <br/>
                                                    <span>
                                                        ( {
                                                            course.type === 'inProgress'
                                                                ? 'In progress' : course.type === 'finished'
                                                                    ? 'Finished' : 'Not assigned'
                                                        } )
                                                    </span>
                                                </p>
                                            }
                                        </Link>
                                        :
                                        <p className={styles.name}>{course.title}</p>
                                    }
                                </td>
                                <td className={styles.date}>
                                    {course.description}
                                </td>
                                {user?.role === ROLES.TEACHER &&
                                    <td>
                                        <div className={styles.divon}>
                                            <img className={styles.img} src={'/images/copyiconuser.png'} alt={'copy'}/>
                                            <Link to={`/${user.role.toLowerCase()}/courses/${course.id}/settings`}>
                                                <img className={styles.img} src={'/images/paniconuser.png'} alt={'pan'} />
                                            </Link>
                                            <img className={styles.img} onClick={() => {
                                                setDeleteModalOpen(true)
                                                setSelectedCourse(course);
                                            }} src={'/images/basketiconuser.png'} alt={'basket'}/>
                                        </div>
                                    </td>
                                }
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {assign?.length > 10 &&
                    <button
                        className={styles.button}
                        onClick={() => {
                            setCount(count === 10 ? assign.length : 10);
                        }}
                    >
                        {count === assign.length ? "See less" : "See more"}
                    </button>
                }
            </div>)}
            {deleteModalOpen && selectedCourse &&
                <Modal
                    setOpen={setDeleteModalOpen}
                    question={`Are you sure to delete the ${selectedCourse.title} course ?`}
                    onConfirm={() => handleDelete(selectedCourse.id)}
                    confirmText={'Delete'}
                    onClose={() => setDeleteModalOpen(false)}
                    closeText={'Close'}
                />
            }

        </>
    )
}

export default TableAdminCourses;