import React, {useEffect, useState} from "react";
import styles from "./dashlayout.module.css";
import Notifications from "./Notifications/Notifications";
import { useNavigate } from "react-router-dom";
import {ROLES, useUserContext} from "../../Components/UserContext/UserContext";
import {useUserCourseContext} from "../../Components/UserCourseContext/UserCourseContext";
import LanguageSelect from "../../Components/LanguageSelect/LanguageSelect";

function Icons({hasNewMessages, setHasNewMessages, newMessages}) {
    const navigate = useNavigate();
    const { user } = useUserContext();
    const { boughtCourses } = useUserCourseContext()
    const isCourseBought = boughtCourses?.length > 0;
    const [open, setOpen] = useState(false);
    const [notReadMessages, setNotReadMessages] = useState()

    useEffect(() => {
        const readMessagesQuantity = [
            ...(newMessages?.groupChatNotification || []),
            ...(newMessages?.chatNotification || [])
        ]
            .reduce((notificationsQuantity, message) => notificationsQuantity + (message.notification || 0), 0);

        setNotReadMessages(readMessagesQuantity)
    }, [newMessages]);

    const handleNotificationClick = () => {
        setOpen(!open);
    };

    return (
        <div className={styles.iconcont}>
                <div className={styles.cont}>
                    {notReadMessages ? <div className={styles.circleMessage}> {notReadMessages} </div> : undefined}
                    <img
                        src={"/images/messages.png"}
                        alt={"message"}
                        className={`${styles.icon} ${user.role === ROLES.STUDENT && (!isCourseBought ? styles.disabled : '')}`}
                        onClick={() => navigate(`/${user?.role.toLowerCase()}/messages?groupChatId=${user?.groupChats[0]?.id}`)}
                    />
                </div>
                <div className={styles.cont}>
                    {hasNewMessages && <div className={styles.circle}></div>}
                    <img
                        src={"/images/notifications.png"}
                        alt={"notification"}
                        className={`${styles.icon} ${user.role === ROLES.STUDENT && (!isCourseBought ? styles.disabled : '')}`}
                        onClick={handleNotificationClick}
                        id={"notification"}
                    />
                </div>
                <img
                    src={"/images/personal.png"}
                    alt={"personal"}
                    className={styles.icon}
                    onClick={() => navigate(`/${user?.role.toLowerCase()}${(!boughtCourses?.length && user.role === ROLES.STUDENT) ? '/empty' : ''}`)}
                />
                <LanguageSelect />
                {open && <Notifications open={open} setOpen={setOpen} setHasNewMessages={setHasNewMessages} />}
        </div>
    );
}

export default Icons;
